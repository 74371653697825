@import url('https://rsms.me/inter/inter.css');

* {
  box-sizing: border-box;
}

html,
body,
main,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: inter;
  color: black;
  background: #f0f0f0;
}

h1 {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  font-weight: 400;
  font-size: 5em;
  text-align: center;
  letter-spacing: -0.045em;
  white-space: nowrap;
}

h1 span:last-child {
  color: #873740;
  /*font-size: 1rem !important;*/
}
