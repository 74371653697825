@import url('https://rsms.me/inter/inter.css');

* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  overscroll-behavior-y: none;
  font-family: 'Inter var', sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  color: #ddd;
  background: #12071f;
}

a {
  pointer-events: all;
  color: #ddd;
  text-decoration: none;
}

svg {
  fill: #ddd;
}

h1 {
  background: linear-gradient(30deg, #c850c0, #ffcc70);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
